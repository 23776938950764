<script>
import SunCalc from "suncalc";

export default {
  name: "SuninfoCard",
  props: ["log", "site"],
  computed: {
    sunrise() {
      return new Date(this.suninfo.sunrise).toLocaleTimeString("fi-FI", {
        timeZone: this.site.timezone,
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    sunset() {
      return new Date(this.suninfo.sunset).toLocaleTimeString("fi-FI", {
        timeZone: this.site.timezone,
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    suninfo: function () {
      return SunCalc.getTimes(
        new Date(this.log.datetime),
        this.site.coordinate.latitude,
        this.site.coordinate.longitude
      );
    },
  },
};
</script>

<template>
  <div class="col">
    <div class="card">
      <div class="card-body placeholder-glow">
        <h5 class="card-title">Aurinko</h5>
        <div class="card-text">
          <div :class="{ placeholder: !log.datetime }">
            <span class="bi-sunrise" /> Aurinko nousee tänään klo
            <span v-if="log.datetime">{{ sunrise }}</span>
            <span v-if="!log.datetime">00:00</span>
          </div>
          <div :class="{ placeholder: !log.datetime }">
            <span class="bi-sunset" /> Aurinko laskee tänään klo
            <span v-if="log.datetime">{{ sunset }}</span>
            <span v-if="!log.datetime">00:00</span>
          </div>
          <div :class="{ placeholder: !log.datetime }">
            <span class="bi-broadcast" /> Säteilyteho:
            <span v-if="log.datetime">{{ log.sr60ssum }} </span>
            <span v-if="!log.datetime">000</span>
            W/m2
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>